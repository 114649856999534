import React, { useState, useMemo, useEffect } from "react";
import "./App.css";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Slide,
  Paper,
  Chip,
  Autocomplete,
} from "@mui/material";
import { KeyboardArrowDown, Check } from "@mui/icons-material";
import SquishedBubbles from "./SquishedVisualization";

import config from "./config";
import { processHighlights } from "./utils";
import MinimalTextField from "./MinimalTextField";
import HighlightEmbed from "./HighlightEmbed";
import PlayPauseButton from "./PlayPauseButton";

const highlights = require(`${config.highlightsJson}`);

function App() {
  const [selectedNode, setSelectedNode] = useState(null);
  const [showArrow, setShowArrow] = useState(false);
  const [showNodeInfo, setShowNodeInfo] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [initiallySelectedHighlight, setInitiallySelectedHighlight] =
    useState(null);

  // Autocomplete value
  const [selectedHighlightOption, setSelectedHighlightOption] = useState(null);

  const processedHighlights = useMemo(
    () =>
      processHighlights(
        highlights,
        config.combineConversations,
        config.tagsField
      ),
    []
  );

  const {
    conversationIds,
    conversationToHighlights,
    tagsToHighlights,
    parentTagsToHighlights,
  } = processedHighlights;

  const highlightOptions = useMemo(
    () =>
      highlights.map((highlight) => ({
        label: `${highlight.speaker} (${highlight.id})`,
        id: highlight.id,
      })),
    [highlights]
  );

  useEffect(() => {
    if (initiallySelectedHighlight) {
      const selectedHighlight = highlights.find(
        (highlight) => highlight.id === initiallySelectedHighlight
      );
      if (selectedHighlight) {
        handleNodeSelection(selectedHighlight);
      }
    }
  }, [initiallySelectedHighlight]);

  const handleNodeClick = (node) => {
    handleNodeSelection(node);
  };

  const handleNodeSelection = (node) => {
    setSelectedNode(node);
    setShowArrow(node !== null);

    if (node === null) {
      setShowNodeInfo(false);
      setSelectedTag(null);
      setSelectedHighlightOption(null);
    } else {
      // Find the matching option in highlightOptions
      const matchingOption = highlightOptions.find(
        (option) => option.id === node.id
      );
      setSelectedHighlightOption(matchingOption);
    }
  };

  const toggleNodeInfo = () => {
    setShowNodeInfo((prev) => !prev);
  };

  const handleHighlightSelect = (event, value) => {
    setSelectedHighlightOption(value);
    if (value) {
      setInitiallySelectedHighlight(value.id);
    } else {
      setInitiallySelectedHighlight(null);
    }
  };

  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayToggle = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="fullScreen">
      <AppBar position="static" sx={{ backgroundColor: "black" }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            🍭 SenseMap
          </Typography>
          {selectedNode && (
            <PlayPauseButton
              highlight={selectedNode}
              isPlaying={isPlaying}
              onClick={handlePlayToggle}
              size="sm"
            />
          )}
          <Autocomplete
            options={highlightOptions}
            getOptionLabel={(option) => option.label}
            onChange={handleHighlightSelect}
            value={selectedHighlightOption}
            renderInput={(params) => (
              <MinimalTextField
                {...params}
                variant="standard"
                placeholder="Search"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: false,
                  sx: {
                    color: "white",
                    fontSize: "0.875rem",
                  },
                }}
              />
            )}
            sx={{
              width: 180,
              "& .MuiAutocomplete-inputRoot": {
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
          />
          {showArrow && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="arrow"
              onClick={toggleNodeInfo}
              className="pulsingArrow"
              sx={{
                position: "absolute",
                right: "50%",
                transform: "translateX(50%)",
              }}
            >
              {showNodeInfo ? (
                <KeyboardArrowDown
                  sx={{ fontSize: 40, transform: "rotate(180deg)" }}
                />
              ) : (
                <KeyboardArrowDown sx={{ fontSize: 40 }} />
              )}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <div className="visualizationContainer">
        <SquishedBubbles
          conversationIds={conversationIds}
          conversationToHighlights={conversationToHighlights}
          highlights={highlights}
          onNodeClick={handleNodeClick}
          autoconnectHighlights={config.autoconnectHighlights}
          conversationNames={config.conversationNames}
          selectedTag={selectedTag}
          showConversationLabels={config.showConversationLabels}
          initiallySelectedHighlight={initiallySelectedHighlight}
        />
      </div>
      <Slide direction="down" in={showNodeInfo}>
        <Paper
          sx={{
            position: "absolute",
            top: 64, // AppBar height
            left: 0,
            right: 0,
            zIndex: 1200, // Ensure it appears above other content
            padding: 3,
            paddingBottom: 1,
            paddingTop: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent black
            borderRadius: 0,
          }}
        >
          {selectedNode && (
            <>
              <HighlightEmbed
                highlight={selectedNode}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
              />
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {selectedNode.tags.sort().map((tag) => (
                  <Chip
                    key={tag}
                    icon={tag === selectedTag ? <Check /> : null}
                    label={tag}
                    onClick={() => setSelectedTag(tag)}
                    sx={{
                      backgroundColor:
                        tag === selectedTag
                          ? "rgba(255, 255, 255, 0.8)"
                          : "rgba(255, 255, 255, 0.5)",
                      margin: 0.5,
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                      },
                      opacity: selectedTag && tag !== selectedTag ? 0.5 : 1,
                    }}
                  />
                ))}
              </Box>
            </>
          )}
        </Paper>
      </Slide>
    </div>
  );
}

export default App;
