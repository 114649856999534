import React, { useEffect, useState } from "react";
import { HighlightPlayer } from "lvn-embed-api";
import { Box } from "@mui/material";
import PlayPauseButton from "./PlayPauseButton";

function HighlightEmbed({ highlight, isPlaying, setIsPlaying }) {
  const [minimalPlayer, setMinimalPlayer] = useState();

  const handleReady = (player) => {
    // resets the player when it's ready
    player.on("ready", () => {
      if (isPlaying) {
        setIsPlaying(false);
      }
    });
    // resets the player when it ends
    player.on("ended", () => {
      setIsPlaying(false);
    });
  };

  useEffect(() => {
    setMinimalPlayer(
      new HighlightPlayer({
        containerId: "embed-minimal",
        highlightId: highlight.id,
        highlightOptions: {
          type: "minimal",
          width: "100%",
          height: "70",
          textColor: "#ffffff",
          backgroundColor: "rgba(0, 0, 0, 0)",
          highlightColor: highlight.color,
          fontSize: "16px",
          scrolling: true,
          src: "https://origin-embed.fora.io/",
        },
        onReady: handleReady,
      })
    );
    return () => {
      setMinimalPlayer(null);
    };
  }, [highlight]);

  const handlePlayToggle = () => {
    if (minimalPlayer) {
      setIsPlaying(!minimalPlayer.isPlaying);
    }
  };

  useEffect(() => {
    if (minimalPlayer) {
      if (isPlaying) {
        minimalPlayer.play();
      } else {
        minimalPlayer.pause();
      }
    }
  }, [isPlaying]);

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.round(seconds) % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            paddingTop: "0.5rem",
            color: "#ffffff",
            width: "100%",
          }}
        >
          <Box
            style={{
              marginBottom: ".25rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <PlayPauseButton
                onClick={handlePlayToggle}
                highlight={highlight}
                isPlaying={isPlaying}
              />
              <span style={{ fontWeight: "bold", marginRight: "1rem" }}>
                {highlight.speaker}
              </span>
            </Box>
            <span style={{ textAlign: "right", fontWeight: "bold" }}>
              {formatDuration(highlight.duration)}
            </span>
          </Box>
          <div id="embed-minimal"></div>
        </div>
      </div>
    </>
  );
}

export default HighlightEmbed;
