import React from "react";
import { Button } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { isLightColor } from "./utils";

function PlayPauseButton({ onClick, highlight, isPlaying, size = "medium" }) {
  const color = isLightColor(highlight.color || "#2a2a2a")
    ? "#000000"
    : "#ffffff";

  const iconSize = size === "medium" ? 30 : 16;

  return (
    <Button
      className="pulseAudio"
      onClick={onClick}
      sx={{
        marginRight: iconSize > 16 ? "1rem" : "0.5rem",
        backgroundColor: highlight.color,
        color,
        borderRadius: "50%",
        minWidth: iconSize,
        height: iconSize,
        padding: 0,
        "&:hover": {
          backgroundColor: highlight.color,
        },
      }}
    >
      {isPlaying ? (
        <PauseIcon style={{ color }} fontSize={size} />
      ) : (
        <PlayArrowIcon style={{ color }} fontSize={size} />
      )}
    </Button>
  );
}

export default PlayPauseButton;
