import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles"; // Import styled from MUI

// Custom styled TextField for minimal appearance
const MinimalTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    color: "white", // Adjust text color
    backgroundColor: "transparent",
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: "0.875rem",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid rgba(255, 255, 255, 0.5)",
  },
  "& .MuiInput-underline:hover:before": {
    borderBottom: "1px solid rgba(255, 255, 255, 0.75)",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "1px solid white",
  },
  "& .MuiInputLabel-root": {
    display: "none", // Hide the label
  },
  "& .MuiAutocomplete-endAdornment": {
    display: "none", // Hide the dropdown arrow
  },
}));

export default MinimalTextField;
