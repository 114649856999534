// Constants for styling
export const NODE_RADIUS = 5;
export const SELECTED_NODE_RADIUS = 8;
export const NODE_OPACITY = 1;
export const FADED_NODE_OPACITY = 0.3;
export const CURVE_STROKE_WIDTH = 1;
const THICK_CURVE_STROKE_WIDTH = 3;
const CURVE_OPACITY = 0.3;
const HIGHLIGHTED_CURVE_OPACITY = 0.7;
const CURVE_COLOR = "white";
const FADED_CURVE_COLOR = "gray";
export const NODE_HIGHLIGHT_OPACITY = 0.3;

export function processHighlights(
  highlights,
  combineConversations,
  tagsField = "tags"
) {
  const conversationIds = [];
  const conversationToHighlights = {};
  const tagsToHighlights = {};
  const parentTagsToHighlights = {};

  highlights.forEach((highlight, index) => {
    const { conversation_id } = highlight;
    const tags = highlight[tagsField] || [];

    // save the tags in the highlight object
    highlight.tags = tags;

    // Process conversationIds
    if (!conversationIds.includes(conversation_id)) {
      conversationIds.push(conversation_id);
    }

    // Process conversationToHighlights
    if (!conversationToHighlights[conversation_id]) {
      conversationToHighlights[conversation_id] = [];
    }
    conversationToHighlights[conversation_id].push(highlight);

    // Process tagsToHighlights and parentTagsToHighlights
    tags.forEach((tag) => {
      // tagsToHighlights
      if (!tagsToHighlights[tag]) {
        tagsToHighlights[tag] = [];
      }
      tagsToHighlights[tag].push(index);

      // parentTagsToHighlights
      const parentTag = tag.split(" :: ")[0];
      if (!parentTagsToHighlights[parentTag]) {
        parentTagsToHighlights[parentTag] = [];
      }
      parentTagsToHighlights[parentTag].push(index);
    });
  });

  if (combineConversations) {
    console.log("NOT IMPLEMENTED - Combining conversations");
  }

  return {
    conversationIds,
    conversationToHighlights,
    tagsToHighlights,
    parentTagsToHighlights,
  };
}

export const nodesShareTag = (node1, node2, selectedTag) => {
  const node1Tags = new Set(node1.highlight.tags);
  const node2Tags = new Set(node2.highlight.tags);

  if (selectedTag) {
    return node1Tags.has(selectedTag) && node2Tags.has(selectedTag);
  } else {
    for (let tag of node1Tags) {
      if (node2Tags.has(tag)) {
        return true;
      }
    }
    return false;
  }
};

export const nodeHasTag = (node, selectedTag) => {
  return (
    !selectedTag ||
    (node.highlight && node.highlight.tags.includes(selectedTag))
  );
};

export const getCurveStyle = (d, selectedTag) => {
  if (nodesShareTag(d.sourceNode, d.targetNode, selectedTag)) {
    return {
      stroke: CURVE_COLOR,
      opacity: HIGHLIGHTED_CURVE_OPACITY,
      strokeWidth: THICK_CURVE_STROKE_WIDTH,
    };
  } else {
    return {
      stroke: FADED_CURVE_COLOR,
      opacity: CURVE_OPACITY,
      strokeWidth: CURVE_STROKE_WIDTH,
    };
  }
};

export const generateNodesAndLinks = (
  conversationIds,
  conversationToHighlights
) => {
  const centralNode = { id: "Central Node" };
  const nodes = [centralNode];
  const links = [];
  const subnetworksCount = conversationIds.length;

  for (let i = 0; i < subnetworksCount; i++) {
    const conversationId = conversationIds[i];
    const subnetworkId = `Subnetwork-${i}`;
    nodes.push({ id: subnetworkId, conversationId });
    links.push({ source: "Central Node", target: subnetworkId });

    const nodesInSubnetwork = conversationToHighlights[conversationId];

    for (let j = 0; j < nodesInSubnetwork.length; j++) {
      const nodeId = `${subnetworkId} - Node ${j}`;
      nodes.push({ id: nodeId, highlight: nodesInSubnetwork[j] });
      links.push({ source: subnetworkId, target: nodeId });
    }
  }

  return { nodes, links };
};

// determine if the color is light
export const isLightColor = (color) => {
  const hex = color.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 155; // Higher value means lighter color
};
